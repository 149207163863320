var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"has-text-centered"},[_c('h2',{staticClass:"title is-1"},[_vm._v(" Que bom ter você na "+_vm._s(_vm.$store.state.ie.shortname)+"! ")]),_c('h3',{staticClass:"subtitle"},[_vm._v(" Faça seu login abaixo ")])]),_c('div',{staticClass:"columns is-centered mt-5"},[_c('div',{class:[
				_vm.enviroment.isPrd ? 'is-4-fullhd' : 'is-5-fullhd',
				'column is-6-desktop'
			]},[_c('form',{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleLoginSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[(_vm.showOfficeLogin)?_c('UIInput',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","label":"CPF","placeholder":"CPF","validation":_vm.$v.form.cpf,"error":{
								required: '*Campo obrigatório.',
								invalid: '*CPF inválido.',
							},"required":""},scopedSlots:_vm._u([{key:"addonAfter",fn:function(){return [_c('span',{staticClass:"button is-static"},[_vm._v(" "+_vm._s(_vm.$store.state.emailLoginDomain)+" ")])]},proxy:true}],null,false,3085053747),model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}):_c('UIInput',{directives:[{name:"mask",rawName:"v-mask",value:('##############'),expression:"'##############'"}],attrs:{"id":"cpf","label":_vm.labelMatricula,"placeholder":_vm.labelMatricula,"validation":_vm.$v.form.user,"error":{
								required: '*Campo obrigatório.',
							},"required":""},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}})],1),(_vm.showLocalLogin)?_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"senha","type":"password","label":"Senha","validation":_vm.$v.form.password,"error":{
								minLength: '*A quantidade de caracteres é menor que 8.',
								invalid: '*Campo inválido.',
								required: '*Campo obrigatório.',
								strong: '*A senha deve conter números e letras maiúsculas e minúsculas.'
							},"required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('router-link',{staticClass:"is-link is-block has-text-primary m-4",attrs:{"to":{ name: 'login.recover' }}},[_vm._v(" Esqueci minha senha ")]),(_vm.showOfficeLogin && !_vm.showLocalLogin)?_c('router-link',{staticClass:"is-link is-block m-4 login-alternativo",attrs:{"to":{ path: '/login/matricula' }}},[_vm._v(" Tenho mais de uma matrícula ")]):(
								_vm.allowOfficeLogin
									&& !_vm.showOfficeLogin
									&& (_vm.$route.path != '/login')
							)?_c('router-link',{staticClass:"is-link is-block m-4 login-alternativo",attrs:{"to":{ path: '/login' }}},[_vm._v(" Fazer login com Office ")]):_vm._e()],1),(_vm.showLocalLogin)?_c('div',{staticClass:"column is-12 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded"},[_vm._v(" Acessar ")])]):_vm._e(),_c('b-loading',{attrs:{"can-cancel":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)]),(_vm.showOfficeLogin)?_c('div',{staticClass:"column is-12 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded",on:{"click":_vm.loginOffice}},[_vm._v(" Login Office ")])]):_vm._e(),_c('FaleConosco')],1)]),(_vm.showOfficeLogin && !_vm.isLoading)?_c('ModalNewLogin'):_vm._e(),_c('Recaptcha',{ref:"grecaptcha"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }